import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import "../index.css";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { CottageRounded } from "@mui/icons-material";

const ResponsiveAppBar = () => {
  return (
    <AppBar position="static" className="appBar">
      <Container
        maxWidth="lg"
        sx={{
          height: "inherit",
        }}
      >
        <Grid
          container
          maxWidth="lg"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: "inherit",
          }}
        >
          <Grid item>
            <Stack direction={"row"} spacing={2}>
              <CottageRounded color="disabled" sx={{ color: "#7065F0", fontSize: "50px" }}></CottageRounded>
              <Typography width={"100px"} fontSize={"40px"} fontWeight="600">
                Eeleme
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction={"row"} spacing={2}>
              <Button variant="contained" size="large">
                {" "}
                Sign up
              </Button>
              <Button variant="outlined" size="large">
                Log in
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
// 100A55
