import { Container, Typography } from "@mui/material";
import Link from "@mui/material/Link";

export function Copyright(props: any) {
  return (
    <Container>
      <Typography display={"inline"} fontSize={"20px"} ml={"10px"} sx={{ color: "white" }}>
        Website Designed & Developed by
      </Typography>
      {/* <Typography
        variant="body2"
        color="text.secondary"
        // align="center"
        fontWeight={"bold"}
        m={3}
      >
        Developer
      </Typography> */}
      <Typography display={"inline"} fontSize={"20px"} ml={"10px"} sx={{ color: "white" }}>
        <Link color="inherit" href="https://www.yamijo.com" underline="none">
          Yamijo
        </Link>{" "}
        {/* {new Date().getFullYear()}
        {"."} */}
      </Typography>
      {/* <Typography variant="body2" color="text.secondary" align="center" fontWeight={"bold"} m={3}>
        SC507184
      </Typography> */}
    </Container>
  );
}
