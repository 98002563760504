import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function createData(name: string, enabled: boolean) {
  return { name, enabled };
}

const rows = [
  createData("Portfolio", true),
  createData("Mobile app", true),
  createData("Document Management", true),
  createData("Workflow Management", true),
  createData("Financial Reports", true),
  createData("Property Managemnt", true),
  createData("Tenancy Management", true),
];

export default function PlanDetails() {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Features </TableCell>
            <TableCell align="right">Enabled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">
                {row.enabled ? <CheckCircleIcon color={"primary"} /> : <HighlightOffIcon />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
