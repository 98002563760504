import { useEffect } from "react";
import Api from "./services/api";
import { Routes, Route } from "react-router-dom";
import routes from "./config/routes";

function App() {
  useEffect(() => {
    // eslint-disable-next-line
    async function apiCall() {
      try {
        // eslint-disable-next-line
        const apiCall = await Api.api();
      } catch (err) {
        console.log(err);
        console.log("Error occured when fetching books");
      }
    }
    // apiCall();
    console.log("Hello");
  }, []);
  return (
    <div className="App">
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
      </Routes>
    </div>
  );
}

export default App;
