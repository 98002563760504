import axios from "axios";
import { auth } from "../config/firebase";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD;

axios.interceptors.request.use((config) => {
  return new Promise(async (resolve, _) => {
    config.headers!.Authorization = `Bearer ${await auth.currentUser?.getIdToken()}`;
    process.env.NODE_ENV !== "development"
      ? resolve(config)
      : setTimeout(() => {
          resolve(config);
        }, 1000);
  });
});

axios.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject<string>(error.response.data.message);
  }
);

export class Api {
  static async api() {
    return axios.get<never, any>("/");
  }
}

export default Api;
