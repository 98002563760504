import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Public Sans", "sans-serif"].join(","),
    fontSize: 14,
    body1: {
      color: "#100a55",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          "&:hover": {
            filter: "brightness(0.85)",
          },
          // ...(ownerState.color !== "primary" && {
          //   backgroundColor: "#283e4a",
          // }),
        }),
      },
    },
  },
});
