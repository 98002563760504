import { Container, Box, Typography, Button, Avatar } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import logo from "./../assets/error.png";

export default function Page404() {
  return (
    <Container style={{ height: "100vh", display: "flex" }}>
      <Box
        sx={{
          // maxWidth: 480,
          margin: "auto",
          textAlign: "center",
          // display: "flex",
          // alignItems: "center",
          direction: "row",
        }}
      >
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          Sorry, we couldn’t find the page you’re looking for. <br />
          Perhaps you’ve mistyped the URL?
          <br />
          Be sure to check your spelling.
        </Typography>
        <Box style={{ display: "flex", padding: "50px", justifyContent: "center" }}>
          <Avatar alt="Remy Sharp" src={logo} sx={{ height: 600, width: 600 }} />
        </Box>
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
      </Box>
    </Container>
  );
}
