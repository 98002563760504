import ResponsiveAppBar from "./app-bar";
import Footer from "./footer";
import Hero from "./hero";
import LandingBody from "./landing-body";
import Plans from "./plans";

export default function Index() {
  return (
    <>
      <ResponsiveAppBar />
      <Hero />
      <LandingBody />
      <Plans />
      <Footer></Footer>
    </>
  );
}
