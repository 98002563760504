import "../App.css";
import { Box, Button, Container, Grid, Typography } from "@mui/material";

function Hero() {
  return (
    <div className="App-header">
      <Grid container maxWidth={"false"}>
        <Grid item sx={{ height: "inherit" }} xs={6} justifyContent={"center"} alignContent={"center"}>
          <Container sx={{ border: "1px solid transparent", width: "min-content" }}>
            <Typography fontSize={"3em"} fontWeight={"500"} align={"left"} lineHeight={1.2} m={3}>
              Property management platform
            </Typography>
            <Button variant="contained" color="error" fullWidth size="large">
              Request a Demo
            </Button>
          </Container>
        </Grid>
        <Grid item sx={{ height: "inherit" }} xs={6}>
          <Box className="hero" display={"flex"} alignItems={"center"}></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Hero;
