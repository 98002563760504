import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import "../index.css";
import { EmailOutlined } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Copyright } from "./copy-right";

const Footer = () => {
  return (
    <Grid item container alignItems={"center"} className="footer" justifyContent={"center"}>
      <Grid item container maxWidth="lg" alignItems={"center"} justifyContent={"center"}>
        <Grid item p={10}>
          <Typography color={"lightgray"} variant={"h3"}>
            Are you a property manager, developer or landlord ?
          </Typography>
          <Typography color={"lightgray"} variant={"h6"} m={5}>
            Join our community and get access to ways to enhance your portfolio
          </Typography>
          <FormControl sx={{ m: 1, width: "50%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
            <OutlinedInput
              sx={{
                bgcolor: "white",
              }}
              id="email-link"
              type="email"
              endAdornment={
                <InputAdornment position="end">
                  <Button variant="contained" startIcon={<EmailOutlined />}>
                    Join us
                  </Button>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
        <Grid item height={"200px"} container maxWidth={"false"} textAlign={"left"} p={2}>
          <Grid item xs={4}>
            <Typography color={"lightgray"} variant={"h6"} fontWeight={"600"} pb={3}>
              PROPERTY
            </Typography>
            <Typography color={"grey"} variant={"h6"}>
              How it works
            </Typography>
            <Typography color={"grey"} variant={"h6"}>
              Pricing
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color={"lightgray"} variant={"h6"} fontWeight={"600"} pb={3}>
              ABOUT
            </Typography>
            <Typography color={"grey"} variant={"h6"}>
              Company
            </Typography>
            <Typography color={"grey"} variant={"h6"}>
              Contact us
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography color={"lightgray"} variant={"h6"} fontWeight={"600"} pb={3}>
              RESOURCES
            </Typography>
            <Typography color={"grey"} variant={"h6"}>
              Blog
            </Typography>
            <Typography color={"grey"} variant={"h6"}>
              FAQ
            </Typography>
            <Typography color={"grey"} variant={"h6"}>
              Help Center
            </Typography>
          </Grid>
        </Grid>

        <Grid item m={5} container justifyContent={"center"} color={"grey"}>
          <FacebookIcon
            sx={{
              fontSize: "60px",
              p: 2,
              cursor: "pointer",
              ":hover": {
                color: "white",
              },
            }}
          />
          <InstagramIcon
            sx={{
              fontSize: "60px",
              p: 2,
              cursor: "pointer",
              ":hover": {
                color: "white",
              },
            }}
          />
          <LinkedInIcon
            sx={{
              fontSize: "60px",
              p: 2,
              cursor: "pointer",
              ":hover": {
                color: "white",
              },
            }}
          />
        </Grid>
        <Grid item m={1} container justifyContent={"center"} color={"lightgray"}>
          <Typography variant="h6">Company Number : SC812340</Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent={"center"} color={"lightgray"}>
        <Grid item xs={12} width={"100%"}>
          <Divider sx={{ bgcolor: "white", m: 5 }} />
        </Grid>
        <Grid item xs={12} pb={10} justifyContent={"center"} color={"lightgray"} container>
          <Copyright />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
