import { FC } from "react";
import "../App.css";
import {
  Avatar,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import { SvgIconComponent } from "@mui/icons-material";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

const FeatureGrid: FC<{
  title: string;
  message: string;
  FeatureIcon?: SvgIconComponent;
  briefDescriptions?: string[];
}> = ({ title, message, FeatureIcon, briefDescriptions }): JSX.Element => {
  return (
    <Paper
      elevation={0}
      sx={{
        border: "2px solid #E0DEF7",
        minHeight: "350px",
        borderRadius: "10px",
        bgcolor: "transparent",
        cursor: "pointer",
        ":hover": {
          bgcolor: "white",
          border: "0px solid white",
          // boxShadow:
          //   "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        },
      }}
    >
      <Typography variant="h6" p={2} noWrap>
        {title}
      </Typography>
      <Divider variant={"middle"} />
      <Grid
        container
        justifyContent={"space-between"}
        // height={"inherit"}
        p={2}
        wrap="nowrap"
        textAlign={"left"}
        direction={"column"}
        alignItems={"center"}
        className="features"
      >
        <Grid item>
          <Avatar
            sx={{ bgcolor: "#E0DEF7", width: "5em", height: "5em", border: "1px dashed black" }}
            // className="features"
          >
            <Avatar sx={{ bgcolor: "white", width: "4.5em", height: "4.5em" }} className="avatarAnimation">
              <Avatar
                sx={{ bgcolor: "white", width: "4em", height: "4em", border: "1px dashed #E0DEF7" }}
                className="noAvatarAnimation"
              >
                {FeatureIcon ? (
                  <FeatureIcon
                    fontSize="large"
                    sx={{ fontSize: "3em", color: "#100a55" }}
                    className="noAvatarAnimation"
                  />
                ) : (
                  <PictureAsPdfOutlinedIcon fontSize="large" />
                )}
              </Avatar>
            </Avatar>
          </Avatar>
        </Grid>
        <Grid item width={"100%"}>
          <List dense={true}>
            {briefDescriptions &&
              briefDescriptions.length > 0 &&
              briefDescriptions.map((value, i) => (
                <Container key={i} maxWidth={false}>
                  <ListItem>
                    <ListItemAvatar>
                      {/* <Avatar> */}
                      <TipsAndUpdatesIcon />
                      {/* </Avatar> */}
                    </ListItemAvatar>
                    <ListItemText
                      primary={value}
                      // secondary={secondary ? 'Secondary text' : null}
                    />
                  </ListItem>
                  <Divider component="li" sx={{ color: "orange" }} />
                </Container>
              ))}
          </List>
          <Typography fontWeight={"500"} variant="h6">
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

function LandingBody() {
  return (
    <Container maxWidth={false} sx={{ justifyContent: "center", display: "flex", mt: 4 }}>
      <Grid container maxWidth={"xl"} columnSpacing={3} rowSpacing={3}>
        <Grid item xs={4}>
          <FeatureGrid
            title="Portfolio & Property management"
            message=""
            FeatureIcon={WorkOutlineIcon}
            briefDescriptions={["Manage vacancies", "Update property information", "Property report"]}
          />
        </Grid>
        <Grid item xs={4}>
          <FeatureGrid
            title="Cloud Native & Real time "
            message=""
            FeatureIcon={PhoneAndroidOutlinedIcon}
            briefDescriptions={["Real time notification", "Access data anywhere", "Infinite scalability"]}
          />
        </Grid>
        <Grid item xs={4}>
          <FeatureGrid
            title="Document Management"
            message=""
            FeatureIcon={PictureAsPdfOutlinedIcon}
            briefDescriptions={["Share or restrict access", "Paperless", "Process unstructure data with AI *"]}
          />
        </Grid>
        <Grid item xs={4}>
          <FeatureGrid
            title="Workflow Management"
            message=""
            FeatureIcon={SchemaOutlinedIcon}
            briefDescriptions={["Integration with leading platforms", "Automate routines", "Triggers"]}
          />
        </Grid>
        <Grid item xs={4}>
          <FeatureGrid
            title="Financial Reports"
            message=""
            FeatureIcon={AccountBalanceWalletOutlinedIcon}
            briefDescriptions={["Real time analytics", "Daily, Weekly or Monthly Reports", "Reconcilation *"]}
          />
        </Grid>

        <Grid item xs={4}>
          <FeatureGrid
            title="Tenancy Management"
            message=""
            FeatureIcon={HandshakeOutlinedIcon}
            briefDescriptions={[
              "Report issue",
              "Access, update & share information with tenent",
              "Track certifications *",
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default LandingBody;
