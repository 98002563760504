import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, Auth } from "firebase/auth";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
import config from "./config";
import { getStorage, connectStorageEmulator, FirebaseStorage } from "firebase/storage";

let auth: Auth;
const app = initializeApp(config);
const storage: FirebaseStorage = getStorage(app);
const analytics = getAnalytics(app);
if (process.env.NODE_ENV === "development") {
  console.log("Config");
  auth = getAuth();
  connectAuthEmulator(auth, "http://127.0.0.1:9095");
  connectStorageEmulator(storage, "localhost", 9098);
  setAnalyticsCollectionEnabled(analytics, true);
} else {
  auth = getAuth(app);
  setAnalyticsCollectionEnabled(analytics, false);
}

export { auth, analytics };
