import "../App.css";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import PlanDetails from "./plan-details";
import { FC } from "react";

const PlanGrid: FC<{ title: string; cost: number; limitation: string }> = ({
  title,
  cost,
  limitation,
}): JSX.Element => {
  return (
    <Grid item xs={4}>
      <Paper sx={{ border: "2px solid #E0DEF7", height: "100%", bgcolor: "#F7F7FD" }} elevation={0}>
        <Grid container justifyContent={"space-between"} direction={"column"} height={"inherit"} p={2} wrap="nowrap">
          <Grid item xs={1}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography fontWeight={"600"} variant="h2">
              £{cost}
            </Typography>
            <Typography>per user /per month</Typography>
            <PlanDetails />
          </Grid>
          <Grid item xs={1} zeroMinWidth>
            <Typography>
              <Button variant="outlined" fullWidth>
                Buy now
              </Button>
            </Typography>
            <Typography pt={2}>{limitation}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default function Plans() {
  return (
    <Container className="landing-body" maxWidth={false}>
      <Container maxWidth={"lg"}>
        <Typography fontSize={"100px"}>Plans</Typography>
        <Grid container columnSpacing={4}>
          <PlanGrid title="Free" cost={0} limitation="All features are enabled but limited to one property" />
          <PlanGrid title="Startup" cost={9} limitation="All features are enabled but limited to 3 properties" />
          <PlanGrid
            title="Advance"
            cost={20}
            limitation="Maximum of 20
                  properies and lots more customization"
          />
        </Grid>
      </Container>
    </Container>
  );
}
