import App from "../components";
import Page404 from "../components/page-not-found";
import { Blogs } from "../components/blogs";
import { IRoute } from "../util/interface";

const routes: IRoute[] = [
  {
    path: "/",
    component: App,
    name: "Home",
    protected: false,
  },
  {
    path: "/blogs",
    component: Blogs,
    name: "Blogs",
    protected: true,
    extra: {
      title: "Blogs",
    },
  },
  {
    path: "*",
    component: Page404,
    name: "Not Found",
    protected: false,
  },
];

export default routes;
